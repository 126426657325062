












































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { reservationModule } from '@/store';
import { mixins } from 'vue-class-component';
import dataFormat from '@/shared/utils/dataFormat';
import { Notify } from 'quasar';
import { EventBus } from '@/config/config';
import AlertModal from '@/components/popup/AlertModal.vue';

@Component({
  components: {
    AlertModal,
    'installment-guide-modal': () => import('@/components/popup/InstallmentGuideModal.vue'),
    'spinner': () => import('@/components/common/Spinner.vue'),
    'privacy-popup': () => import('@/components/popup/PrivacyPopup.vue'),
    'reserve-before-check': () => import('@/components/popup/ReserveBeforeCheck.vue')
  }
})
export default class Payment extends mixins(dataFormat) {
  @Prop()
  public cartId!: any;
  // @Prop()
  // public selectLang!: any;

  public payCard: any = null;
  public errorMessage: string = '보유 포인트 내역이 변경되었습니다. 포인트 사용을 확인해주세요.';
  public isLoading: boolean = false;
  public agreeGroup = {
    agree1: false,
    agree2: false,
    agree3: false,
    agree4: false
  };
  public agreeAll: boolean = false;
  public params: any = {
    cart_id: this.cartId,
    pay_type: 'direct_pay',
    point: 0,
    message: ''
    // card_id: 0
  };
  public cardData: any = {
    card_type: 'P',
    card_number: '',
    exp_year: '',
    exp_month: '',
    card_pw: '',
    id_no: ''
  };
  public cardNo: string[] = ['', '', '', ''];
  public bizNo: string[] = ['', '', ''];
  public privacyType: string = '';
  public pgTypes: any = [];
  public pageTop: number = 0;
  public realTop: number = 0;
  public time: any = {
    minute: 4,
    second: 59
  }
  public timeInterval: any = null;
  public requestMessage: string = '';
  public ReserveBeforeCheckStatus: boolean = false;

  public mounted() {
    this.initData();
    document.addEventListener('scroll', this.scrollEvent);
    this.validTimeCheck();
    const secondData = `payment-limit-second-${this.cartId}`;
    const minuteData = `payment-limit-minute-${this.cartId}`;
    const addData = `payment-limit-add-${this.cartId}`;
    setTimeout(() => {
      localStorage.removeItem(secondData);
      localStorage.removeItem(minuteData);
      localStorage.removeItem(addData);
    }, 1000 * 60 * 20)
  }
  public initData() {
    this.params.cart_id = this.cartId;
    this.cardNo = ['', '', '', ''];
    this.bizNo = ['', '', ''];
    this.cardData = {
      card_type: 'P',
      card_number: '',
      exp_year: '',
      exp_month: '',
      card_pw: '',
      id_no: ''
    };
    this.params.pay_type = 'direct_pay';
    // if (this.selectLang !== 'ko') {
    //   this.params.pay_type = 'paypal';
    // } else {
    //   this.params.pay_type = 'direct_pay';
    // }
  }
  public validTimeCheck() {
    const secondData = `payment-limit-second-${this.cartId}`;
    const minuteData = `payment-limit-minute-${this.cartId}`;
    if (
      localStorage.getItem(secondData) &&
      localStorage.getItem(minuteData)
    ) {
      if (
        Number(localStorage.getItem(secondData)) < 0 ||
        Number(localStorage.getItem(minuteData)) < 0
      ) {
        Notify.create({
          message: '구매 정보 유효시간이 만료되었습니다. 다시 구매 시도해주세요.'
        });
        reservationModule.deleteCart(this.cartId);
        this.$router.go(-1);
        return;
      }
      this.time.second =
        Number(localStorage.getItem(secondData)) === 0 ? 59 : Number(localStorage.getItem(secondData)) - 1;
      this.time.minute = Number(localStorage.getItem(minuteData));
    }
    this.timeInterval = setInterval(() => {
      localStorage.setItem(secondData, this.time.second);
      localStorage.setItem(minuteData, this.time.minute);
      this.time.second = this.time.second - 1;
      if (this.time.second < 0) {
        this.time.second = 59;
        this.time.minute = this.time.minute - 1;
      }
      if (this.time.second === 0 && this.time.minute === 0) {
        Notify.create({
          message: '구매 정보 유효시간이 만료되었습니다. 다시 구매 시도해주세요.'
        });
        clearInterval(this.timeInterval);
        reservationModule.deleteCart(this.cartId);
        this.$router.go(-1);
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(this.timeInterval);
    }, 1000 * 60 * 20);
  }
  public numberInput(e) {
    e = (e) ? e : window.event;
    const charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      e.preventDefault();
    } else {
      return true;
    }
  }

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.query.type !== 'fail') {
        reservationModule.fetchCartData(to.params.cartId).then((res: any) => {
          if (res.data) {
            vm.pgTypes = res.data.pg_types;
            // if (
            //   (localStorage.getItem('OS_DEPOSIT_LANG') &&
            //   localStorage.getItem('OS_DEPOSIT_LANG') === 'en') ||
            //   (res.data.user.email !== '' &&
            //   res.data.user.phone.includes('+'))
            // ) {
            //   const re = vm.pgTypes;
            //   let target = vm.pgTypes[5];
            //   let target2 = vm.pgTypes[6];
            //   re.splice(5, 2);
            //   re.unshift(target2);
            //   re.unshift(target);
            //   vm.pgTypes = re;


            //   vm.$nextTick(() => {
            //     vm.params.pay_type = 'paypal';
            //   });
            // }
            if (res.data.reservation.message) {
              vm.params.message = res.data.reservation.message;
            }
          }
          if (res.error) {
            Notify.create({
              message: res.error.message
            });
          }
        });
      } else {
        vm.errorMessage = to.query.message;
        vm.$nextTick(() => {
          EventBus.$emit('errorMessage', to.query.message);
          vm.$modal.show('alert');
        });
      }
    });
  }

  public beforeRouteLeave(to, from, next) {
    localStorage.removeItem('cartData');
    next();
  }

  public ReserveBeforeCheckUpdate() {
    this.ReserveBeforeCheckStatus = false;
    this.$nextTick(() => {
      this.paymentDataSetting();
    });
  }

  // 결제 params 설정
  public paymentClick() {
    if (this.cartData.place.reserve_checklist.length > 0) {
      this.ReserveBeforeCheckStatus = true;
    } else {
      this.paymentDataSetting();
    }
  }
  public paymentDataSetting() {
    if (!this.paymentValidate) {
      const addData = `payment-limit-add-${this.cartId}`;
      if (this.params.pay_type === 'direct_pay') {
        this.cardData.card_number = [this.cardNo[0], this.cardNo[1], this.cardNo[2], this.cardNo[3]].join('');
        if (this.cardData.card_type === 'C') {
          this.cardData.id_no = [this.bizNo[0], this.bizNo[1], this.bizNo[2]].join('');
        }
        const params = {
          ...this.params,
          ...this.cardData
        }
        if (localStorage.getItem(addData) !== 'true') {
          this.time.minute = this.time.minute + 10;
        }
        this.buy(params);
      } else {
        if (localStorage.getItem(addData) !== 'true') {
          if (
            this.params.pay_type === 'paypal' ||
            this.params.pay_type === 'eximbay'
          ) {
            this.time.minute = this.time.minute + 15;
          } else {
            this.time.minute = this.time.minute + 10;
          }
        }
        this.buy(this.params);
      }
      localStorage.setItem(addData, 'true');
    }
  }
  public buy(params?: any) {
    this.isLoading = true;
    // if (this.params.pay_type === 'nice') this.params.card_id = this.payCard.value.id;
    // else this.params.card_id = null;

    reservationModule.fetchReservationRegister(params).then((res) => {
      this.isLoading = false;
      if (res.status) {
        if (res.data.id) {
          const id = decodeURIComponent(window.btoa(res.data.id));
          this.$router.push({ path: `/payment/result/success?reservation_id=${res.data.id}` });
          return;
        }
        localStorage.cartData = JSON.stringify(this.cartData);
        const popupCheck = window.open(res.data.url);
        if (!popupCheck || popupCheck.closed || typeof popupCheck.closed === 'undefined') {
          EventBus.$emit('errorMessage', '팝업이 차단되어 있습니다.</br> 팝업차단을 해제하신 뒤 다시 시도하여 주십시오.');
          this.$modal.show('alert');
        }
      } else {
        this.errorMessage = res.error.message;
        EventBus.$emit('errorMessage', this.errorMessage);
        this.$modal.show('alert');
        clearInterval(this.timeInterval);
        this.time.second = 0;
        this.time.minute = 0;
      }
    })
    .finally(() => {
      EventBus.$emit('reserveBeforeCheckLoading', false);
    });
  }

  public openPopup(type: string) {
    this.realTop = this.pageTop;
    this.privacyType = type;
    this.$modal.show('privacyPopup');
  }
  public scrollEvent(info: any) {
    const el = document.querySelector('html') as any;
    this.pageTop = el.scrollTop;
  }
  public scrollSet(data: any) {
    const el = document.querySelector('html') as any;
    el.scrollTop = data;
  }

  @Watch('agreeAll')
  public agreeAllUpdate() {
    if (this.agreeAll) {
      this.agreeGroup = {
        agree1: true,
        agree2: true,
        agree3: true,
        agree4: true
      };
    } else {
      if (
        this.agreeGroup.agree1 &&
        this.agreeGroup.agree2 &&
        this.agreeGroup.agree3 &&
        this.agreeGroup.agree4
      ) {
        this.agreeGroup = {
          agree1: false,
          agree2: false,
          agree3: false,
          agree4: false
        };
      }
    }
  }
  @Watch('agreeGroup', {
    deep: true
  })
  public agreeGroupUpdate() {
    if (
      this.agreeGroup.agree1 &&
      this.agreeGroup.agree2 &&
      this.agreeGroup.agree3 &&
      this.agreeGroup.agree4
    ) {
      this.agreeAll = true;
    }
    if (
      !this.agreeGroup.agree1 ||
      !this.agreeGroup.agree2 ||
      !this.agreeGroup.agree3 ||
      !this.agreeGroup.agree4
    ) {
      this.agreeAll = false;
    }
  }
  @Watch('ReserveBeforeCheckStatus')
  public ReserveBeforeCheckStatusUpdate() {
    if (this.ReserveBeforeCheckStatus === false) {
      document.querySelector('html' as any).classList.remove('open-modal');
    } else {
      document.querySelector('html' as any).classList.add('open-modal');
    }
  }
  public destroyed() {
    clearInterval(this.timeInterval);
  }

  get paymentValidate() {
    if (this.params.pay_type === 'direct_pay') {
      let isCardNo = false;
      let isCardData = false;

      this.cardNo.forEach((v) => {
        if (v || v.length === 4) isCardNo = true;
        else isCardNo = false;
      });

      if (this.cardData.card_type === 'C') {
        this.bizNo.forEach((v) => {
          if (v) isCardNo = true;
          else isCardNo = false;
        });
      }

      for (const data in this.cardData) {
        if (this.cardData[data]) {
          isCardData = true;
        } else if (data === 'card_number' || (this.cardData.card_type === 'C' && data === 'id_no')) {
          continue;
        } else {
          isCardData = false;
          break;
        }
      }

      if (this.agreeGroup.agree1 && this.agreeGroup.agree2 && this.agreeGroup.agree3 && this.agreeGroup.agree4 && !this.isLoading && isCardData && isCardNo) {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.agreeGroup.agree1 && this.agreeGroup.agree2 && this.agreeGroup.agree3 && this.agreeGroup.agree4 && !this.isLoading) {
        return false;
      } else {
        return true;
      }
    }
  }

  get cartData() {
    if (this.$route.query.type === 'fail') return JSON.parse(localStorage.cartData);
    else return reservationModule.cartInfo;
  }

  // 인원 성인만 있는지 체크
  get onlyAdultCheck() {
    const re = this.cartData.reservation.party_size_detail.filter((row: any) => {
      return row.title !== '성인' && Number(row.party_size) !== 0;
    });
    if (re.length) {
      return true;
    }
    return false;
  }
  // 마지막 인원 타입
  get lastPersonKindIndex() {
    let type = '';
    this.cartData.reservation.party_size_detail.filter((row: any) => {
      if (row.party_size !== 0) {
        type = row.title;
      }
    });
    if (type !== '') {
      return this.cartData.reservation.party_size_detail.findIndex((row: any) => {
        return row.title === type;
      })
    }
    return 0;
  }
  get reserveChecklist() {
    if (this.cartData.place.reserve_checklist.length) {
      const re: any = [];
      this.cartData.place.reserve_checklist.filter((row: any, index: any) => {
        re[index] = {};
        re[index].value = row;
        re[index].active = false;
      });
      return re;
    }
    return null;
  }
}

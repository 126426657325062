










import { Vue, Component } from 'vue-property-decorator';
import { EventBus } from '@/config/config';

@Component
export default class AlertModal extends Vue {
  public message: any = '';

  public mounted() {
    EventBus.$on('errorMessage', (data) => {
      this.message = data;
    })
  }
}
